<template>
  <div class="main">
    <div class="main-title">学科能力测评报告</div>
    <div class="query-info-wrapper">
      <div class="questionnaire">
        <div class="part-title">综合报告</div>
        <div class="part-content">
          <p>
            学科能力测评报告是在测评数据的支持下，从学生实际能力出发出发，针对学生测评结果对学生学科能力做出的分析。通过此项测，可帮助了解各科情况同时根据各项能力值给到匹配的选科与专业推荐。
          </p>
          <div id="container" style="min-height: 400px; width: 100%">
            <highcharts :options="chartOptions"></highcharts>
          </div>
          <p>
            学科综合能力分析是指结合学科基础能力和学生人格特征进行测评，并对学生测评结果做出的综合分析。通过此项，可帮助了解各科学科能力高低水平、对学生测评结果做出的综合分析。
          </p>
        </div>

        <div
          class="part-block"
          v-for="(subjectName, index) in subjectNames"
          :key="index"
        >
          <div class="not-done" v-if="!judegeDone(subjectName[1])">
            <div>
              <p>您{{ subjectName[0] }}科目暂未完成</p>
            </div>
          </div>
          <div v-else>
            <div class="part-title">{{ subjectName[0] }}分析报告</div>
            <div class="part-content">
              <p>
                经系统分析，您{{ subjectName[0] }}科目的能力得分为：<span
                  >{{ assresults[subjectName[1]] }}分</span
                >
              </p>

              <!-- 饼图:错题类别分布 -->
              <div
                class="container-wrong-point"
                :id="'container-wrong-point-' + subjectName[1]"
                style="min-height: 300px; width: 100%"
              ></div>

              <p>
                您本次{{ subjectName[0] }}测试中出现错误的考点为<strong
                  v-for="(p, index1) in getWrongPointList(subjectName[1])"
                  :key="index1 + 20"
                  >{{ p }}，</strong
                ><span>其中</span
                ><span
                  v-for="(p, index2) in getWrongPointRate(subjectName[1])"
                  :key="index2 + 60"
                  >{{ p[0] }}占{{ p[1] }}，</span
                >
              </p>
              <div
                class="container-wrong-rate"
                :id="'container-wrong-rate-' + subjectName[1]"
                style="min-height: 300px; width: 100%"
              ></div>

              <p>
                错误题目难度中，<span
                  v-for="(d, index3) in getWrongDifficulityRate(subjectName[1])"
                  :key="index3 + 1000"
                  >{{ d[0] }}占{{ d[1] }}，</span
                ><span>根据分析，您对{{ subjectName[0] }}的掌握</span
                ><strong>{{ getMasteryDegree(subjectName[1]) }}</strong
                ><span>。</span>
              </p>
              <hr />
              <p>
                以下为系统结合您的错误考点，给出的针对性学习建议，点击蓝色标签可以查看对应项目的详情：
              </p>
              <div
                v-for="(t, index4) in getTips(subjectName[1])"
                :key="index4 + 100"
              >
                <p>
                  <strong>{{ t[0] }}</strong>
                </p>
                <p>{{ t[1] }}</p>
                <div
                  class="sub-tip"
                  @click="onSubTipClick(sub)"
                  v-for="(sub, index5) in t[2]"
                  :key="index5 + 200"
                  :class="{
                    full: isSubTipShown(sub),
                    'inline-block': !isSubTipShown(sub),
                    'lj-btn': !isSubTipShown(sub),
                    'lj-btn-third': !isSubTipShown(sub)
                  }"
                >
                  <div class="sub-tip-title">{{ sub[0] }}</div>
                  <div class="sub-tip-content" v-if="isSubTipShown(sub)">
                    {{ sub[1] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="button-wrapper">
          <a class="lj-btn lj-btn-primary lj-btn-block" @click="backContent()"
            >返回目录
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './../../../Public/utils/chartjs/Chart'
import './../../../Public/utils/highcharts/highcharts'
import { querySubjectDone, querySubjectResult } from './../../../api/subject'
import $ from 'jquery'
import './../../../Public/utils/new-index/js/jarallax'
import './../../../Public/utils/new-index/js/jquery.totemticker'
import './../../../Public/utils/new-index/js/move-top'
import './../../../Public/utils/new-index/js/easing'
$(function () {
  $('#vertical-ticker').totemticker({
    row_height: '75px',
    next: '#ticker-next',
    previous: '#ticker-previous',
    stop: '#stop',
    start: '#start',
    mousestop: true
  })
})

$(document).ready(function ($) {
  $('.scroll').click(function (event) {
    event.preventDefault()
    $('html,body').animate(
      {
        scrollTop: $(this.hash).offset().top
      },
      1000
    )
  })
})

$(document).ready(function () {
  $().UItoTop({
    easingType: 'easeOutQuart'
  })
})

export default {
  data: function () {
    return {
      loading: false,

      currentSubTip: null,

      subjectNames: [
        ['语文', 'chinese'],
        ['数学', 'math'],
        ['英语', 'english'],
        ['物理', 'physics'],
        ['化学', 'chemistry'],
        ['生物', 'biology'],
        ['政治', 'politics'],
        ['历史', 'history'],
        ['地理', 'geography']
      ],
      description: {
        chinese: {
          1: [
            '语言文学',
            '语言文学考点考察基础语文知识，您在学习过程中要加强对基础知识的掌握，熟悉常考字音字形，注意词语和标点符号使用的方式方法，对病句修改、句式变换要较为熟练，了解常用修辞手法及其作用。',
            {
              1: [
                '识记字音',
                '本考点考察对汉字字音的掌握，常考前后鼻音和易错音等，在学习中要加强对常见字字音的认识。'
              ],
              2: [
                '辨识字形',
                '本考点考察对汉字字形的认识，常考易错字，在学习中要加强对常见字字形的认识。'
              ],
              3: [
                '正确使用词语（实词、虚词）',
                '本考点考察对词语使用的理解，常考常用词语的位置等，在学习中要加强对词语意义的理解。'
              ],
              4: [
                '正确使用熟语（包括成语）',
                '本考点考察对熟语使用的理解，常考常用熟语、成语的含义、作用等，在学习中要加强对成语和熟语的积累，同时了解其意义。'
              ],
              5: [
                '标点符号的使用',
                '本考点考察对标点符号的使用，常考不同位置标点符号使用的正确性和不同语气语句中使用的正确性，在学习中要加强对标点符号作用和语句语气的认识。'
              ],
              6: [
                '图文转换',
                '本考点考察对图片内容的观察、理解能力，在学习中要提高理解能力，同时强化语言组织能力。'
              ],
              7: [
                '扩展语段',
                '本考点考察对语言的理解、创作能力，在学习中要提高理解能力，同时提高写作水平。'
              ],
              8: [
                '压缩语段',
                '本考点考察对文本的理解、概括能力，在学习中要强化理解能力，锻炼概括语句的能力。'
              ],
              9: [
                '语句连贯（排序）',
                '本考点考察对文本逻辑的认识能力，在学习中要锻炼逻辑思考能力，加强对文本连续性的认识。'
              ],
              10: [
                '语言得体',
                '本考点考察对语言的理解能力，在学习中要加强对特定词语、熟语的含义的理解，认识词汇的不同作用。'
              ],
              11: [
                '仿写句式',
                '本考点考察对句子的理解能力和写作能力，在学习中要加强对文本的理解能力，同时提高写作能力，正确认识句子的句法和含义。'
              ],
              12: [
                '辨析并修改病句',
                '本考点考察句法，在学习过程中要了解各种句子的结构，同时掌握句子的逻辑性，掌握各种修改病句的方法，增加练习。'
              ],
              13: [
                '逻辑推断',
                '本考点考察对逻辑性的理解，在学习过程中要强化逻辑关系的认知能力，锻炼逻辑思维。'
              ],
              14: [
                '选用、变换句式',
                '本考点考察对句子形式的理解，在学习过程中要认识各种句式，掌握各种句式的形式变换方法。'
              ],
              15: [
                '语言表达简明、准确、鲜明、生动',
                '本考点考察写作能力和文字表达能力，在学习过程中要加强对文字写作、组织能力的锻炼，同时掌握正确、简洁的表达方式。'
              ],
              16: [
                '正确运用常见修辞手法',
                '本考点考察对常用修辞手法的掌握，在学习过程中要加强对常用修辞手法作用的了解和使用意义。'
              ]
            }
          ],
          2: [
            '文言文阅读',
            '文言文阅读考点在学习过程中要增强常用实词、虚词各种含义的记忆和理解，了解文言句段的结构形式，掌握翻译方法。',
            {
              1: [
                '理解实词在文中的含义',
                '本考点考察对文言实词的理解，在学习过程中要加强对各种文言实词含义的掌握。'
              ],
              2: [
                '理解虚词的意义和用法',
                '本考点考察对文言虚词的理解，在学习过程中要了解各种虚词的常见用法及其含义。'
              ],
              3: [
                '理解、分析文言句段',
                '本考点考察对文言句段的理解和分析能力，在学习过程中要加强对文言的熟悉程度和相关知识的了解，理解文言句段的含义，分析其中表达的思想。'
              ],
              4: [
                '文言语段综合训练',
                '本考点考察对文言文中包含各考点的综合掌握，在学习过程中要加强对文言各种知识的掌握。'
              ]
            }
          ],
          3: [
            '名句名篇与常识',
            '名句名篇与常识考点在学习过程中要强化对重点考察篇目的背诵，同时加强对文化知识、文学常识的了解。',
            {
              1: [
                '文化常识',
                '本考点考察对各种文化常识的了解，在学习过程中要加强对文化常识的掌握。'
              ],
              2: [
                '文学常识',
                '本考点考察对文学常识的了解，在学习过程中要加强对文学史、作家名篇等文学常识的掌握。'
              ],
              3: [
                '名句',
                '本考点考察对要求掌握的名句的掌握程度，在学习过程中要强化记忆。'
              ],
              4: [
                '名篇',
                '本考点考察对要求掌握的名篇的掌握程度，在学习过程中要强化背诵。'
              ]
            }
          ],
          4: [
            '古代诗歌鉴赏',
            '古代诗歌鉴赏考点在学习过程中要加强对古诗文鉴赏方法的掌握，同时熟悉各种题型。',
            {
              1: [
                '思想内容、观点态度、感情类题目',
                '本考点考察对古代诗歌包含的思想感情和作者态度的理解，在学习过程中要增加对古代诗歌的了解，强化对古代诗歌含义和内容的理解能力，能通过诗句分析其中表达的思想。'
              ],
              2: [
                '诗歌常识',
                '本考点考察对古代诗歌常识的了解，在学习过程中要加强对诗歌中包含的文学史常识等内容的了解。'
              ],
              3: [
                '古代诗歌鉴赏综合练习',
                '本考点考察对古代诗歌的综合鉴赏能力，在学习过程中要能够通过诗句分析诗歌表达的思想感情，能够通过关键字和创作背景等了解作者的态度。'
              ],
              4: [
                '形象、意象、意境类题目',
                '本考点考察对诗歌中用于表达感情的各种事物的作用的了解，在学习过程中要加强根据诗歌整体分析各种事物的作用和含义的能力。'
              ],
              5: [
                '语言风格类题目',
                '本考点考察理解诗歌风格的能力，在学习过程中要能够根据诗歌的语言特征分析其风格，同时要了解各种常用的诗歌语言风格'
              ],
              6: [
                '表达技巧类题目',
                '本考点考察对诗歌表达技巧的了解，在学习过程中要加强对诗歌的分析、认识能力，能够根据诗句分析其表达技巧。'
              ]
            }
          ]
        },
        math: {
          1: [
            '代数',
            '在学习过程中要对概念有清楚的理解，同时掌握多种函数的形式和特点及其一阶导数的形式。',
            {
              1: [
                '集合',
                '本考点考察对集合概念和各种性质，在学习过程中要加强对概念的掌握和理解。'
              ],
              2: [
                '常用逻辑用语',
                '本考点考察对常用逻辑用语的掌握，在学习过程中要掌握各种常用逻辑用语的含义及其作用。'
              ],
              3: [
                '函数概念',
                '本考点考察对函数概念的掌握，在学习过程中要加强对基础概念的理解。'
              ],
              4: [
                '基本初等函数I',
                '本考点考察对基本初等函数的掌握，在学习过程中要了解基本初等函数的概念、性质、特点的认识。'
              ],
              5: [
                '导数及其应用',
                '本考点考察对导数及其应用的掌握，在学习过程中要加强对导数概念及其各种应用的掌握。'
              ],
              6: [
                '不等式',
                '本考点考察对不等式有关的知识，在学习过程中加强对不等式概念及几种常用不等式的了解及其应用。'
              ],
              7: [
                '数列',
                '本考点考察对数列知识的了解，在学习过程中加强对数列概念、性质及其应用的掌握。'
              ],
              8: [
                '平面向量',
                '本考点考察对平面向量知识的了解，在学习过程中要加强对平面向量的概念的掌握，同时加强结合几何进行分析、应用的能力。'
              ],
              9: [
                '数系的扩充与复数',
                '本考点考察对数系和复数知识的理解和掌握，在学习过程中要加强对基础概念的理解，同时提高运算能力，避免在细节上出错。'
              ]
            }
          ],
          2: [
            '排列组合与概率统计',
            '概率统计部分在学习过程中要掌握排列组合常用的公式，同时对各种题型有一定的认识，加强对题目的理解和对概念的掌握。',
            {
              1: [
                '统计与统计案例',
                '本考点考察对统计知识的了解，在学习过程中要掌握统计常用的各种公式及指标，提高对具体案例的分析能力，同时锻炼画图、识图能力。'
              ],
              2: [
                '概率',
                '本考点考察对概率各种知识的掌握，在学习过程中要掌握常用概率的类型，同时掌握概率的计算公式，能根据不同情况和问题属于的类型进行相关的计算。'
              ],
              3: [
                '计数原理',
                '本考点考察对计数原理知识的掌握，该考点常结合排列组合、概率统计等内容进行考察，在学习过程中要掌握排列组合的计算公式等知识。'
              ]
            }
          ],
          3: [
            '算法与框图',
            '在学习过程中要注意对概念的理解，强化对公式、性质的掌握，了解各种题型。',
            {
              1: [
                '算法与框图',
                '本考点考察对算法、框图识别的掌握，在学习过程中要加强对框图中各种不同功能的图例的作用的掌握，同时提高转换简单算法的能力。'
              ]
            }
          ],
          4: [
            '推理与证明',
            '在学习过程中要注意对概念的理解，强化对公式、性质的掌握，了解各种题型。',
            {
              1: [
                '推理与证明',
                '本考点考察对逻辑推理和证明的理解和掌握，在学习过程中要注意逻辑推理的过程，提高逻辑思维能力，对一些基础证明要了解其证明方法。'
              ]
            }
          ],
          5: [
            '三角函数',
            '三角函数部分在学习过程中要增强对三角函数各种性质的掌握，同时对周期、对称等特点有充分了解，熟悉常用三角函数的变换和对应的图像，能用三角函数解三角形。',
            {
              1: [
                '任意角与任意角三角函数',
                '本考点考察任意角与任意角三角函数有关知识，在学习过程中要加强对三角函数基础知识及特点的掌握，提高计算能力，能够根据基础形式进行各种变形。'
              ],
              2: [
                '三角函数的图像与性质',
                '本考点考察三角函数的图像和性质，在学习过程中要掌握基础三角函数的图像，能够根据基础图像结合题目进行图像的变换，掌握周期等各种性质。'
              ],
              3: [
                '三角恒等变换',
                '本考点考察三角函数变换的知识，在学习过程中要扎实掌握各种三角函数的变换，同时能够进行计算。'
              ],
              4: [
                '解直角三角形',
                '本考点考察解直角三角形的知识，在学习过程中要能够根据题目实际情况，利用各种三角函数，对直角三角形的各种未知量进行计算。'
              ]
            }
          ],
          6: [
            '平面解析几何',
            '在学习过程中要掌握常用的平面几何和立体几何性质，了解常用的方法，在解题过程中注重数形结合以及分析。',
            {
              1: [
                '直线与方程',
                '本考点考察直线和方程的有关内容，在学习过程中要加强对直线各种形式的一般方程的掌握，同时提高计算能力。'
              ],
              2: [
                '圆与方程',
                '本考点考察圆及其方程的知识，在学习过程中要掌握圆的各种性质及其表示公式，能够结合直线等平面几何知识进行计算，同时要加强计算能力。'
              ],
              3: [
                '圆锥曲线与方程',
                '本考点考察圆锥曲线及其有关方程的知识，在学习过程中要加强对圆锥曲线各种形式的掌握，同时加强分析能力，提高对圆锥曲线和其他平面几何知识综合考察题目的认识。'
              ]
            }
          ],
          7: [
            '立体几何',
            '在学习过程中要掌握常用的平面几何和立体几何性质，了解常用的方法，在解题过程中注重数形结合以及分析。',
            {
              1: [
                '空间几何体',
                '本考点考察空间几何知识，在学习过程中要加强空间想象能力，提高利用向量等方法对空间几何体各种特性进行计算和证明的能力。'
              ],
              2: [
                '空间向量与立体几何',
                '本考点考察空间向量和立体几何的综合分析能力，在学习过程中要能够结合空间向量和立体几何知识进行分析，提高计算能力和空间想象能力。'
              ]
            }
          ]
        },
        english: {
          1: [
            '词法',
            '词法部分在学习过程中要加强单词等基础内容的学习，了解其性质、用法及在句子中一般发挥的作用。',
            {
              1: [
                '冠词',
                '本考点考察对冠词的认识，在学习过程中要加强对冠词性质、作用、使用方法的掌握和了解。'
              ],
              2: [
                '名词',
                '本考点考察对名词的认识，在学习过程中要加强对名词性质、作用、使用方法的掌握和了解'
              ],
              3: [
                '代词',
                '本考点考察对代词的认识，在学习过程中要加强对代词性质、作用、使用方法的掌握和了解'
              ],
              4: [
                '数词',
                '本考点考察对数词的认识，在学习过程中要加强对数词性质、作用、使用方法的掌握和了解'
              ],
              5: [
                '介词和介词短语',
                '本考点考察对介词和介词短语的认识，在学习过程中要加强对介词和介词短语性质、作用、使用方法的掌握和了解，同时强化对常用介词短语的记忆。'
              ],
              6: [
                '形容词',
                '本考点考察对形容词的认识，在学习过程中要加强对形容词性质、作用、使用方法的掌握和了解'
              ],
              7: [
                '副词',
                '本考点考察对副词的认识，在学习过程中要加强对副词性质、作用、使用方法的掌握和了解'
              ],
              8: [
                '情态动词',
                '本考点考察对情态动词的认识，在学习过程中要加强对情态动词性质、作用、使用方法的掌握和了解'
              ],
              9: [
                '虚拟语气',
                '本考点考察对虚拟语气的掌握，在学习过程中要掌握虚拟语气的一般形式，提高使用、识别虚拟语气形式的能力。'
              ],
              10: [
                '动词及动词短语辨析',
                '本考点考察对动词及动词短语辨析的认识，在学习过程中要加强对动词及动词短语辨析质、作用、使用方法的掌握和了解，同时强化对常用动词短语的记忆，提高不同动词、动词短语的作用的认识。'
              ],
              11: [
                '非谓语动词',
                '本考点考察对非谓语动词的认识，在学习过程中要加强对非谓语动词性质、作用、使用方法的掌握和了解'
              ],
              12: [
                '动词的时态',
                '本考点考察动词的时态知识，在学习过程中提高对常用动词不同时态的掌握，能够在不同时态背景下对动词进行变形。'
              ],
              13: [
                '动词的语态',
                '本考点考察动词的语态知识，在学习过程中提高对常用动词在不同语态情况下的形式变化的知识的掌握。'
              ],
              14: [
                '连词',
                '本考点考察对连词的认识，在学习过程中要加强对连词性质、作用、使用方法的掌握和了解'
              ],
              15: [
                '固定短语',
                '本考点考察对固定短语的掌握，在学习过程中要加强对固定短语的记忆，提高根据不同时态、语态应用固定短语的能力，能够对常见固定短语进行补全。'
              ],
              18: [
                '词性辨析',
                '本考点考察词性的辨析，在学习过程中要能够根据词性的不同对性质、用法、含义进行辨析，提高分析能力。'
              ]
            }
          ],
          2: [
            '句法',
            '句法部分在学习过程中要掌握常用的各种时态、语法，加强阅读能力，了解各种句子的结构等特点。',
            {
              1: [
                '并列句',
                '本考点考察并列句有关知识，在学习过程中提高对并列句性质、作用、使用方法的掌握和了解。'
              ],
              2: [
                '定语从句',
                '本考点考察定语从句有关知识，在学习过程中提高对定语从句性质、作用、使用方法的掌握和了解。'
              ],
              3: [
                '名词性从句',
                '本考点考察名词性从句有关知识，在学习过程中提高对名词性从句性质、作用、使用方法的掌握和了解。'
              ],
              4: [
                '状语从句',
                '本考点考察状语从句有关知识，在学习过程中提高对状语从句性质、作用、使用方法的掌握和了解。'
              ],
              5: [
                '句式',
                '本考点综合考察各种句式，在学习过程中加强各种句式的特点的掌握，能够识别不同句式。'
              ]
            }
          ]
        },
        physics: {
          1: [
            '力学',
            '力学部分在学习过程中要注重对概念的理解，掌握各种常用的公式，了解各种运动形式的特点，强化运动的分析能力，同时注重读图能力的培养。',
            {
              1: [
                '运动的描述',
                '本考点考察对运动的描述的了解，在学习过程中要能够根据运动的特点，进行正确的表达，描述运动的主要性质。'
              ],
              2: [
                '匀变速直线运动',
                '本考点考察对匀变速直线运动性质、特点和有关计算的知识，在学习过程中要能够识别各种不同的匀变速直线运动，同时加强计算能力，对运动中的各种未知量进行解算。'
              ],
              3: [
                '相互作用',
                '本考点考察相互作用有关知识，在学习过程中要能够分析不同物体之间的相互作用，提高分析能力。'
              ],
              4: [
                '牛顿运动定律',
                '本考点考察牛顿运动定律的知识，在学习过程中要能够掌握、记忆牛顿运动定律，结合各种运动情况进行计算，同时加强计算能力。'
              ],
              5: [
                '曲线运动',
                '本考点考察曲线运动有关知识，在学习过程中要能够根据曲线运动特点进行有关计算，提高对运动性质的认识和计算能力。'
              ],
              6: [
                '机械运动',
                '本考点考察机械运动有关知识，在学习过程中提高对机械运动性质、特点的掌握，加强计算能力。'
              ]
            }
          ],
          2: [
            '电磁学',
            '电磁学部分在学习过程中要掌握各种常见的公式，增强分析能力，同时注重读图能力的培养。',
            {
              1: [
                '电场',
                '本考点考察电场有关知识，在学习过程中要提高分析能力，掌握电场中电荷运动的规律和有关公式，能够利用有关知识进行计算。'
              ],
              2: [
                '电路',
                '本考点考察电路有关知识，在学习过程中要了解各种电路形式，掌握不同形式电路的特点和性质，能够利用有关公式进行电路中各种物理量的计算，同时加强对电路特点的记忆，能够对不完整的电路进行补全和连接。'
              ],
              3: [
                '磁场',
                '本考点考察磁场有关知识，在学习过程中要能够提高分析能力，根据磁场性质进行有关计算，同时了解不同情况下的受力和运动情况。'
              ],
              4: [
                '电磁感应',
                '本考点考察电磁感应有关知识，在学习过程中要加强对电磁感应基础概念的掌握，能够根据具体情况对电磁感应性质进行分析。'
              ],
              5: [
                '交变电流',
                '本考点考察交变电流有关知识，在学习过程中加强对交变电流有关基础概念的掌握，根据有关知识对题目具体情况进行分析。'
              ],
              6: [
                '门电路传感器',
                '本考点考察门电路有关知识，在学习过程中加强对门电路传感器的性质和特点的认识，了解门电路传感器的应用。'
              ],
              7: [
                '电磁波',
                '本考点考察电磁波有关知识，在学习过程中加强对电磁波有关概念的了解，能够结合有关题目进行性质和特点的分析。'
              ],
              8: [
                '复合场',
                '本考点考察电场和磁场的复合场，在学习过程中加强对电场和磁场基础知识的掌握，提高对在电场和磁场复合场中运动的物体的运动规律的分析能力，能够结合题目给出的各项数据进行运动状态的判定和有关值的计算，掌握常见的复合场中运动的规律和状态。'
              ]
            }
          ],
          3: [
            '物理实验',
            '物理实验部分在学习过程中要掌握各种仪器的使用方法、加强计算能力，能正确的处理仪器的数据，培养读图能力，加强根据图像对出现的错误或数据进行分析的能力。',
            {
              1: [
                '实验基础，力学实验，电磁学实验',
                '本考点考察有关实验的各项内容，在学习过程中要提高实验基础知识的掌握，包括常见仪器的正确使用方法和读数方法等，同时对不同的实验类型，能够对读取到的数据进行分析，提高对误差等特殊情况的处理能力。'
              ]
            }
          ],
          4: [
            '物理学史和研究方法',
            '',
            {
              1: [
                '物理学史和研究方法',
                '本考点考察物理学史和研究方法有关知识，在学习过程中要强化对物理学史重点人物、知识的记忆，对常用的研究方法要加强记忆和理解。'
              ]
            }
          ],
          5: [
            '单位制及量纲',
            '',
            {
              1: [
                '单位制及量纲',
                '本考点考察常用的物理学单位制和量纲，在学习过程中要掌握不同物理量使用的量纲，熟练记忆单位之间的换算关系，提高计算能力。'
              ]
            }
          ]
        },
        chemistry: {
          1: [
            '化学科学简介',
            '化学学科简介部分在学习过程中要加强对概念和知识的了解。',
            {
              1: [
                '化学科学特点',
                '本考点考察对化学科学特点的了解，在学习过程中要加强对有关基础概念的掌握。'
              ],
              2: [
                '化学研究方法与工业化学',
                '本考点考察化学研究方法和工业化学有关知识，在学习过程中要加强对各种研究方法的了解，同时掌握一定的工业化学知识及生产流程等。'
              ],
              3: [
                '化学与生活',
                '本考点考察化学与生活知识，在学习过程中要了解各种和化学有关的生活常识，能够使用化学知识解释生活中的现象。'
              ],
              4: [
                '化学用语',
                '本考点考察对化学用语的了解，在学习过程中要掌握常见的化学用语及其含义，并能够进行正确的使用。'
              ]
            }
          ],
          2: [
            '常见无机物及其应用',
            '常见无机物及其应用部分在学习过程中要掌握基本概念和各种元素的性质，了解各种常见的知识，同时加强对分析能力的锻炼。',
            {
              1: [
                '氧族与碳族及其应用',
                '本考点考察对氧族和碳族元素的应用，在学习过程要熟练掌握氧族和碳族元素的基本性质和特点，能够根据题目情况进行分析。'
              ],
              2: [
                '金属及其化合物',
                '本考点考察金属及其化合物知识，在学习过程中要掌握金属元素基本性质，加强分析能力，熟练掌握各种金属及其化合物的性质和特点。'
              ]
            }
          ],
          3: [
            '化学反应原理',
            '化学反应原理部分在学习过程中要注重掌握基本概念，有较强的综合分析能力，清楚各种反应的过程，并有一定的计算能力。',
            {
              1: [
                '元素周期律',
                '本考点考察元素周期律有关内容，在学习过程中要强化对有关知识的记忆。'
              ],
              2: [
                '化学反应与能量',
                '本考点考察化学反应及其中的能量知识，在学习过程中要能够分析化学反应情况，正确书写反应方程，加强计算能力。'
              ],
              3: [
                '化学反应速率和化学平衡',
                '本考点考察化学反应速率和化学平衡知识，在学习过程中要熟练掌握化学反应的速率规律，了解平衡的发生条件，能够根据给出的数据进行相关的计算。'
              ],
              4: [
                '电解质溶液',
                '本考点考察电解质溶液知识，在学习过程中要掌握各种电解质的性质及特点，能够进行分析。'
              ]
            }
          ],
          4: [
            '化学实验',
            '化学实验部分一般是对知识的综合考察。您在学习过程中要掌握各种仪器的使用方法，强化对基础知识的掌握，同时锻炼综合分析能力。',
            {
              1: [
                '化学实验',
                '化学实验部分一般是对知识的综合考察。您在学习过程中要掌握各种仪器的使用方法，强化对基础知识的掌握，同时锻炼综合分析能力。'
              ]
            }
          ]
        },
        biology: {
          1: [
            '分子与细胞',
            '分子与细胞部分学习过程中要强化对基础知识的掌握，同时注重和其他知识的结合。',
            {
              1: [
                '细胞的分子组成',
                '本考点考察细胞的分子组成知识，在学习过程中要加强对基础概念的理解，掌握细胞内的分子组成情况。'
              ],
              2: [
                '细胞的结构',
                '本考点考察细胞的结构知识，在学习过程中要加强对细胞结构的认识。'
              ],
              3: [
                '细胞的代谢',
                '本考点考察细胞的代谢知识，在学习过程中要加强基础概念的记忆，对细胞内的代谢过程进行掌握。'
              ],
              4: [
                '细胞的增殖',
                '本考点考察细胞的增殖知识，在学习过程中要熟练掌握细胞的增殖规律，特别是两类分裂过程的规律、特点和分裂过程中出现的各时期情况的掌握，能够结合题目情况进行分析。'
              ],
              5: [
                '细胞的分化、衰老和凋亡',
                '本考点考察细胞的分化、衰老和凋亡，在学习过程中要掌握三个过程的规律，同时提高结合题目对癌变等常见考点进行分析的能力。'
              ]
            }
          ],
          2: [
            '遗传与进化',
            '遗传与进化部分在学习过程中要强化基础概念的掌握，了解各种遗传的规律，同时锻炼分析能力。',
            {
              1: [
                '遗传的细胞基础',
                '本考点考察细胞的遗传基础，在学习过程中要掌握细胞层面的遗传规律，能够根据基础概念进行分析。'
              ],
              2: [
                '遗传的分子基础',
                '本考点考察遗传的分子基础，在学习过程中要掌握分子层面的遗传规律，对在遗传过程中涉及的各类遗传物质的特点、性质要熟练掌握。'
              ],
              3: [
                '遗传的基本规律',
                '本考点考察遗传的基本规律，在学习过程中要掌握遗传过程中基本概念和各种特点，熟练记忆遗传过程中出现的规律性表现。'
              ],
              4: [
                '生物的变异',
                '本考点考察生物的变异知识，在学习过程中要掌握有关变异的基本概念和发生的条件、表现等知识。'
              ],
              5: [
                '人类遗传病',
                '本考点考察人类遗传病知识，在学习过程中要掌握人各种类遗传病的产生原因、具体表现，并在不同层面上进行解释。'
              ],
              6: [
                '生物的进化',
                '本考点考察生物的进化知识，在学习过程中要能够根据进化规律解释各种现象，同时掌握有关进化的基础概念。'
              ]
            }
          ],
          3: [
            '稳态与环境',
            '稳态与环境部分在学习中要注重基础概念的掌握，同时了解各种活动的过程，增强综合分析能力。',
            {
              1: [
                '植物的激素调节',
                '本考点考察植物的激素调节知识，在学习过程中要了解各种调节物质的特点、作用，能够结合题目对具体情况进行分析。'
              ],
              2: [
                '动物生命活动的调节',
                '本考点考察动物生命活动的调节知识，在学习过程中要了解动物生命调节的过程及表现，能够结合题目对其中的过程和特点进行分析。'
              ],
              3: [
                '人体的内环境与稳态',
                '本考点考察人体的内环境与稳态知识，在学习过程中要明确人体内环境和稳态的基础概念，对涉及的规律进行掌握，结合具体问题进行分析。'
              ],
              4: [
                '种群和群落',
                '本考点考察种群和群落知识，在学习过程中要掌握种群、群落等基础概念，能够对不同的概念进行辨析。'
              ],
              5: [
                '生态系统',
                '本考点考察生态系统知识，在学习过程中要对生态系统涉及的基础概念进行记忆，对生态系统中各环节、角色的作用要了解掌握。'
              ],
              6: [
                '生态环境的保护',
                '本考点考察生态环境保护知识，在学习过程中要对有关生态环境保护的各种基础概念和方法进行记忆，能够结合结合题目进行分析。'
              ]
            }
          ]
        },
        politics: {
          1: [
            '经济生活',
            '经济生活部分包括生活与消费、生产、劳动与经营、收入与分配、市场经济。该部分内容与日常生活较为相关，考察方式多样。您在学习过程中要注重强化记忆，加强对重点概念的理解和掌握，锻炼基本的分析能力。',
            {
              1: [
                '生活与消费',
                '本考点考察生活与消费知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，对生活中与消费有关的现象要加强理解。'
              ],
              2: [
                '生产、劳动与经营',
                '本考点考察生产、劳动与经营知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              3: [
                '收入与分配',
                '本考点考察收入与分配知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，了解收入分配的多种形式及其特征。'
              ],
              4: [
                '市场经济',
                '本考点考察市场经济知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，掌握市场经济发挥的作用和意义等内容。'
              ]
            }
          ],
          2: [
            '政治生活',
            '政治生活部分包括公民的政治生活、我国政府、发展社会主义民主政治、当代国际社会。该部分内容在考察中占据的比例较大。您在学习过程中要注重强化重点知识的掌握，对各种常识有一定的了解，锻炼根据材料的分析能力。',
            {
              1: [
                '公民的政治生活',
                '本考点考察公民的政治生活知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              2: [
                '我国政府',
                '本考点考察我国政府知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，对我国政府的结构和行政权力等内容要加强了解。'
              ],
              3: [
                '发展社会主义民主政治',
                '本考点考察发展社会主义民主知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              4: [
                '当代国际社会',
                '本考点考察当代国际社会知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ]
            }
          ],
          3: [
            '文化生活',
            '文化生活部分包括文化与生活、文化的传承与创新、中华文化与民族精神、发展中国特色社会主义。该部分内容考察形式多样，一般占有较大的比重。您在学习过程中要了解、掌握基本概念，同时锻炼结合材料进行分析的能力。',
            {
              1: [
                '文化与生活',
                '本考点考察文化与生活知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              2: [
                '文化的传承与创新',
                '本考点考察文化的传承与创新知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，对文化的传承原则、创新发展实践要增强了解。'
              ],
              3: [
                '中华文化与民族精神',
                '本考点考察中华文化与民族精神知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，对民族精神的内容及其具体体现要增强了解。'
              ],
              4: [
                '发展中国特色社会主义文化',
                '本考点考察发展中国特色社会主义文化知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力'
              ]
            }
          ],
          4: [
            '生活与哲学',
            '生活与哲学部分包括生活智慧与时代精神、探索世界与追求真理、思想方法与创新意识、认识社会与价值选择。您在学习过程中要加强对基础知识的掌握，注重知识的记忆，锻炼分析能力。',
            {
              1: [
                '生活智慧与时代精神',
                '本考点考察生活智慧和时代精神知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              2: [
                '探索世界与追求真理',
                '本考点考察探索世界与追求真理知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              3: [
                '思想方法与创新意识',
                '本考点考察生活思想方法与创新意识知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ],
              4: [
                '认识社会与价值选择',
                '本考点考察认识社会与价值选择知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力。'
              ]
            }
          ]
        },
        geography: {
          1: [
            '地图、地球与宇宙',
            '',
            {
              1: [
                '地球',
                '本考点考察地球知识，在学习过程中对基础概念要加强记忆，注意和其他考点的结合。'
              ],
              2: [
                '地图、等值线图',
                '本考点考察地图、等值线图知识，在学习过程中要加强识图能力，增强分析能力。'
              ]
            }
          ],
          2: [
            '宇宙中的地球',
            '',
            {
              1: [
                '宇宙环境及其对地球的影响',
                '本考点考察宇宙环境及其对地球的影响知识，在学习过程中要加强对基础概念的掌握。'
              ],
              2: [
                '地球运动及其地理意义',
                '本考点考察地球运动及其地理意义知识，在学习过程中要加强对基础概念的掌握，同时熟记基础知识。'
              ],
              3: [
                '地球的圈层结构',
                '本考点考察地球的圈层结构知识，在学习过程中要加强对结构划分的记忆，强化对重点知识的掌握。'
              ]
            }
          ],
          3: [
            '自然环境中的物质运动和能量交换',
            '',
            {
              1: [
                '地壳物质循环及地表形态的塑造',
                '本考点考察地壳物质循环及地表形态的塑造，在学习过程中要加强对循环过程的理解及基础知识的掌握，了解各个环节发挥的作用。'
              ],
              2: [
                '大气的热状况与大气运动',
                '本考点考察大气的热状况与大气运动，在学习过程中要加强对重点概念的掌握。'
              ],
              3: [
                '水的运动',
                '本考点考察水的运动，在学习过程中要加强对重点概念的掌握。'
              ]
            }
          ],
          4: [
            '自然环境的整体性和差异性',
            '',
            {
              1: [
                '自然地理要素在地理环境形成和演变中的作用(如生物)',
                '本考点考察自然地理要素在地理环境形成和演变中的作用，在学习过程中要了解要素发挥的作用及特点，加强对概念的掌握。'
              ],
              2: [
                '地理环境的整体性',
                '本考点考察地理环境的整体性，在学习过程中要加强对重点概念的掌握。'
              ],
              3: [
                '地理环境的地域分异规律',
                '本考点考察地理环境的地域分异规律，在学习过程中要加强对规律的记忆和不同地域之间差别的辨析。'
              ]
            }
          ],
          5: [
            '自然环境对人类活动的影响',
            '',
            {
              1: [
                '地表形态对聚落及交通线路分布的影响，全球气候变化对人类活动的影响',
                '本考点考察地表形态对聚落及交通线路分布的影响，全球气候变化对人类活动的影响，在学习过程中要加强对基础概念的掌握。'
              ],
              2: [
                '自然资源对人类生存与发展的意义，自然灾害发生的主要原因及危害',
                '本考点考察自然资源对人类生存与发展的意义，自然灾害发生的主要原因及危害，在学习过程中要加强对各种资源的作用、灾害发生的原因及其后果的记忆。'
              ]
            }
          ],
          6: [
            '人口与城市',
            '',
            {
              1: [
                '人口与地理环境',
                '本考点考察人口与地理环境，在学习过程中要加强对基础概念的掌握，了解人口和地理环境互相影响的关系。'
              ],
              2: [
                '城市与地理环境',
                '本考点考察城市与地理环境，在学习过程中要加强对基础概念的掌握，了解城市和地理环境互相影响的关系。'
              ]
            }
          ],
          7: [
            '生产活动与地域联系',
            '',
            {
              1: [
                '农业生产活动与地理环境',
                '本考点考察农业生产活动与地理环境，在学习过程中要加强对基础概念的掌握，了解农业生产活动受地理环境的影响。'
              ],
              2: [
                '工业生产活动与地理环境',
                '本考点考察工业生产活动与地理环境，在学习过程中要加强对基础概念的掌握，了解工业生产活动受地理环境的影响。'
              ],
              3: [
                '交通运输与地理环境',
                '本考点考察交通运输与地理环境，在学习过程中要加强对地理环境制约交通运输发展的了解。'
              ]
            }
          ],
          8: [
            '区域地理',
            '',
            {
              1: [
                '世界地理',
                '本考点考察世界地理，在学习过程中要加强对世界地理基本概念的掌握，同时结合其他知识进行综合分析。'
              ],
              2: [
                '中国地理',
                '本考点考察中国地理，在学习过程中要加强对中国地理基本概念的掌握，同时结合其他知识进行综合分析。'
              ]
            }
          ],
          9: [
            '区域可持续发展',
            '',
            {
              1: [
                '区域可持续发展',
                '本考点考察区域可持续发展，在学习过程中要加强分析能力，了解教材中给出的各种事例的特点，并能加以区分。'
              ],
              2: [
                '人类与地理环境的协调发展',
                '本考点考察人类与地理环境的协调发展，在学习过程中要加强分析能力，掌握重点知识。'
              ],
              3: [
                '区域地理环境与人类活动',
                '本考点考察区域地理环境与人类活动，在学习过程中要加强对区域地理环境和人类活动互相制约的理解，了解各种地理环境对人类活动的不同影响，并能加以区分。'
              ],
              4: [
                '地理信息技术的应用',
                '本考点考察地理信息技术的应用知识，在学习过程中要加强对各种地理信息技术的了解，并记忆不同信息技术的特点及其实际应用。'
              ],
              5: [
                '区域生态环境的建设',
                '本考点考察区域生态环境的建设，在学习过程中要加强对区域生态环境的了解，能够结合题目情况进行具体分析。'
              ]
            }
          ]
        },
        history: {
          1: [
            '古代史',
            '古代史部分在学习过程中要注重对知识的记忆，锻炼从材料中提取关键信息进行分析的能力。',
            {
              1: [
                '古代中国的政治制度',
                '本考点古代中国的政治制度知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，加强对各种政治制度的特点、变化的掌握。'
              ],
              2: [
                '古代中国的经济',
                '本考点考察古代中国的经济知识，在学习过程中要强化对重要知识的记忆，锻炼结合题目进行分析的能力，加强对重点概念的掌握。'
              ],
              3: [
                '中国传统文化主流思想的演变',
                '本考点考察中国传统文化主流思想的演变，在学习过程中要加强对重点知识的掌握，了解各种主流思想的产生背景、发展情况以及演变的原因及其社会作用。'
              ],
              4: [
                '古代中国的科学技术与文学艺术',
                '本考点考察古代中国的科学技术与文学艺术，在学习过程中要加强对重点知识的掌握，\t了解科学技术和文学艺术的基本概念。'
              ],
              5: [
                '古代希腊、罗马的政治制度',
                '本考点考察古代希腊、罗马的政治制度，在学习过程中要加强对希腊、罗马政治制度的特点、性质及其不同的辨析。'
              ],
              6: [
                '西方人文精神的起源',
                '本考点考察西方人文精神的起源，在学习过程中要加强对基础概念的掌握。'
              ]
            }
          ],
          2: [
            '近代史',
            '近代史部分包在学习过程中要注重对重点知识的记忆、锻炼分析能力。',
            {
              1: [
                '西方人文精神的发展',
                '本考点考察西方人文精神的发展，在学习过程中要加强对其发展背景、影响的掌握。'
              ],
              2: [
                '资本主义世界市场的形成和发展',
                '本考点考察资本主义世界市场的形成发展，在学习过程中要掌握市场发展的社会背景和技术因素，了解发展的作用及其产生的影响。'
              ],
              3: [
                '欧美代议制的确立与发展',
                '本考点考察欧美代议制的确立与发展，在学习过程中掌握欧美代议制确立和发展的原因以及时代背景，加强对代议制特点的掌握。'
              ],
              4: [
                '科学社会主义理论的诞生和国际工人运动',
                '本考点考察科学社会主义理论的诞生和国际工人运动，在学习过程中要加强对时代背景和基本概念的掌握。'
              ],
              5: [
                '近代科学技术',
                '本考点考察近代科学技术知识，在学习过程中要加强对近代科学技术发展及其产生的影响的了解。'
              ],
              6: [
                '19世纪的世界文学艺术',
                '本考点考察19世纪的世界文学艺术，在学习过程中要加强对时代背景和基础知识的了解。'
              ],
              7: [
                '近代中国反侵略、求民主的斗争',
                '本考点考察近代中国反侵略、求民主的斗争知识，在学习过程中加强对历次斗争作用、意义的掌握，同时加强对基础概念的记忆。'
              ],
              8: [
                '近代中国经济结构的变动与资本主义的曲折发展',
                '本考点考察近代中国经济结构的变动与资本主义的曲折发展知识，在学习过程中加强对变化过程的掌握，加强记忆历次变化的特点及其作用。'
              ],
              9: [
                '近代中国的思想解放潮流',
                '本考点考察近代中国的思想解放潮流知识，在学习过程中加强对重点概念的掌握以及作用、影响的理解。'
              ],
              10: [
                '新潮冲击下的社会生活和交通通讯的变化',
                '本考点考察新潮冲击下的社会生活和交通通讯的变化，在学习过程中加强对重点概念的掌握。'
              ]
            }
          ],
          3: [
            '现代史',
            '现代史部分在学习过程中要强化重点知识的记忆，同时加强分析能力。',
            {
              1: [
                '俄国十月革命与苏联社会主义建设',
                '本考点考察俄国十月革命与苏联社会主义建设知识，在学习过程中要加强对十月革命意义、作用的记忆，同时对苏联社会主义建设的特点和产生的影响进行了解。'
              ],
              2: [
                '罗斯福新政和当代资本主义的新变化',
                '本考点考察罗斯福新政和当代资本主义的新变化知识，在学习过程中要了解罗斯福新政的出台背景、具体内容及其产生的影响，掌握资本主义新变化的特点。'
              ],
              3: [
                '第二次世界大战后世界政治格局的演变',
                '本考点考察二战后世界政治格局的演变，在学习过程中要加强对世界政治格局演变的特点的掌握，了解其原因及时代背景。'
              ],
              4: [
                '第二次世界大战后世界经济的全球化趋势',
                '本考点考察二战后世界经济的全球化趋势，在学习过程中要加强对全球化趋势的起因、发展及其影响的掌握。'
              ],
              5: [
                '现代科学技术',
                '本考点考察现代科学技术知识，在学习过程中要掌握重点概念。'
              ],
              6: [
                '19世纪以来的世界文学艺术',
                '本考点考察19世纪以来的世界文学艺术，在学习过程中要掌握重点概念。'
              ],
              7: [
                '现代中国的政治建设与祖国统一',
                '本考点考察现代中国的政治建设和祖国统一，在学习过程中要了解相关历史，掌握重点概念。'
              ],
              8: [
                '中国特色社会主义建设的道路',
                '本考点考察中国特色社会主义建设的道路，在学习过程中要加强对重点概念的掌握，提高分析能力。'
              ],
              9: [
                '现代中国的对外关系',
                '本考点考察现代中国的对外关系，在学习过程中要加强对现代中国对外关系原则的变化和各时期外交的特点，加强对重点概念的掌握。'
              ],
              10: [
                '中国现代社会生活的变迁',
                '本考点考察中国现代社会生活的变迁，在学习过程中要加强对各时代变迁的背景及其原因的掌握。'
              ],
              11: [
                '20世纪以来中国重大思想理论成果',
                '本考点考察20世纪以来中国重大思想理论成果，在学习过程中加强对各项重大思想理论成果的特点、主要内容的认识。'
              ],
              12: [
                '现代中国的科学技术与文化',
                '本考点考察现代中国科学技术和文化，在学习过程中要加强对基础概念的记忆。'
              ]
            }
          ]
        }
      },
      assresults: {
        chinese: 20,
        math: 20,
        english: 40,
        physics: 20,
        chemistry: 30,
        biology: 30,
        politics: 29,
        history: 19,
        geography: 78
      },
      details: {
        chinese: [
          [false, 1, 0.6],
          [false, 1, 0.6],
          [true, 1, 0.6],
          [false, 1, 0.2],
          [false, 1, 0.8],
          [true, 1, 0.8],
          [true, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [false, 3, 0.6],
          [false, 3, 0.6],
          [true, 3, 0.6],
          [false, 3, 0.2],
          [false, 3, 0.8],
          [false, 3, 0.8],
          [true, 4, 0.6],
          [false, 4, 0.6],
          [false, 4, 0.6],
          [false, 4, 0.2],
          [false, 4, 0.8],
          [true, 4, 0.8]
        ],
        math: [
          [false, 1, 0.6],
          [true, 1, 0.2],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [false, 3, 0.6],
          [false, 3, 0.2],
          [false, 3, 0.8],
          [false, 3, 0.8],
          [false, 3, 0.8],
          [true, 4, 0.6],
          [false, 4, 0.2],
          [true, 4, 0.8],
          [false, 4, 0.8],
          [false, 4, 0.8],
          [true, 5, 0.6],
          [true, 5, 0.2],
          [false, 5, 0.8],
          [false, 5, 0.8],
          [false, 5, 0.8]
        ],
        english: [
          [true, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.6],
          [true, 1, 0.6],
          [false, 1, 0.6],
          [true, 1, 0.2],
          [true, 1, 0.8],
          [true, 1, 0.8],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [true, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.2],
          [true, 2, 0.8],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [false, 2, 0.8]
        ],
        physics: [
          [false, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.2],
          [true, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [true, 2, 0.2],
          [true, 2, 0.8],
          [false, 2, 0.8],
          [false, 3, 0.6],
          [false, 3, 0.6],
          [false, 3, 0.6],
          [true, 3, 0.2],
          [true, 3, 0.8],
          [false, 3, 0.8],
          [false, 4, 0.6],
          [true, 4, 0.6],
          [false, 4, 0.6],
          [true, 4, 0.2],
          [false, 4, 0.8],
          [false, 4, 0.8]
        ],
        chemistry: [
          [true, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.6],
          [true, 1, 0.2],
          [true, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [true, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [false, 3, 0.6],
          [true, 3, 0.6],
          [true, 3, 0.6],
          [false, 3, 0.2],
          [false, 3, 0.8],
          [false, 3, 0.8],
          [false, 4, 0.6],
          [false, 4, 0.6],
          [false, 4, 0.6],
          [false, 4, 0.2],
          [false, 4, 0.8],
          [true, 4, 0.8]
        ],
        biology: [
          [false, 1, 0.6],
          [true, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.2],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [true, 2, 0.8],
          [false, 3, 0.6],
          [true, 3, 0.6],
          [false, 3, 0.6],
          [true, 3, 0.6],
          [false, 3, 0.2],
          [false, 3, 0.8],
          [false, 3, 0.8],
          [false, 3, 0.8]
        ],
        politics: [
          [false, 1, 0.6],
          [true, 1, 0.6],
          [true, 1, 0.6],
          [false, 1, 0.2],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [true, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [true, 3, 0.6],
          [false, 3, 0.6],
          [false, 3, 0.6],
          [false, 3, 0.2],
          [false, 3, 0.8],
          [false, 3, 0.8],
          [false, 4, 0.6],
          [false, 4, 0.6],
          [true, 4, 0.6],
          [true, 4, 0.2],
          [true, 4, 0.8],
          [false, 4, 0.8]
        ],
        history: [
          [false, 1, 0.6],
          [true, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.2],
          [true, 1, 0.8],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [true, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [true, 2, 0.8],
          [true, 2, 0.8],
          [false, 3, 0.6],
          [false, 3, 0.6],
          [true, 3, 0.6],
          [false, 3, 0.6],
          [false, 3, 0.2],
          [true, 3, 0.8],
          [true, 3, 0.8],
          [false, 3, 0.8]
        ],
        geography: [
          [false, 1, 0.6],
          [true, 1, 0.6],
          [false, 1, 0.6],
          [false, 1, 0.2],
          [false, 1, 0.8],
          [false, 1, 0.8],
          [false, 2, 0.6],
          [true, 2, 0.6],
          [true, 2, 0.6],
          [false, 2, 0.2],
          [false, 2, 0.8],
          [false, 2, 0.8],
          [true, 3, 0.6],
          [false, 3, 0.6],
          [false, 3, 0.6],
          [false, 3, 0.2],
          [false, 3, 0.8],
          [true, 3, 0.8],
          [true, 4, 0.6],
          [false, 4, 0.6],
          [false, 4, 0.6],
          [false, 4, 0.2],
          [true, 4, 0.8],
          [false, 4, 0.8]
        ]
      }
    }
  },
  computed: {
    values: function () {
      return Object.entries(this.assresults)
        .map(function (e) {
          return [e[0], Number(e[1])]
        })
        .reduce(function (e, t) {
          return e[t[0]] || (e[t[0]] = t[1]), e
        }, {})
    },

    chartOptions: function () {
      return {
        chart: { type: 'bar' },
        colors: ['#f17c67', '#2ea9df', '#FFB11B', '#86C166', '#9B90C2'],
        xAxis: {
          categories: [
            '语文',
            '数学',
            '外语',
            '物理',
            '化学',
            '生物',
            '政治',
            '历史',
            '地理'
          ],
          title: { text: '学科', style: { fontSize: '16px' } },
          labels: { step: 1, staggerLines: 1, style: { fontSize: '14px' } }
        },
        title: { text: null },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '能力值',
            align: 'high',
            style: { fontSize: '16px' }
          },
          labels: { format: '{value}' }
        },
        legend: { enabled: !1 },
        tooltip: { valueDecimals: 2, valueSuffix: '' },
        plotOptions: {
          bar: { dataLabels: { enabled: !0, format: '{y}' } },
          series: { pointWidth: 30 }
        },
        credits: { enabled: !1 },
        series: [
          {
            name: '能力值',
            data: [
              this.assresults.chinese,
              this.assresults.math,
              this.assresults.english,
              this.assresults.physics,
              this.assresults.chemistry,
              this.assresults.biology,
              this.assresults.politics,
              this.assresults.history,
              this.assresults.geography
            ]
          }
        ]
      }
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    backContent: function () {
      this.$router.back()
    },
    onSubTipClick: function (e) {
      this.currentSubTip !== e
        ? (this.currentSubTip = e)
        : (this.currentSubTip = null)
    },
    isSubTipShown: function (e) {
      return this.currentSubTip === e
    },
    getMasteryDegree: function (e) {
      var t = this.getdetailObject(e),
        n = Object.values(t).reduce(function (e, t) {
          return e + t.result.f.length
        }, 0),
        r =
          Object.values(t).reduce(function (e, t) {
            var n = t.result.t,
              r = t.result.f,
              i = 0
            return (
              n.forEach(function (e) {
                i += e
              }),
              r.forEach(function (e) {
                i -= e
              }),
              e + i
            )
          }, 0) / n
      return r < -0.4
        ? '较差，需要重点加强'
        : r < 0
        ? '情况中等，有待进一步提升'
        : r < 0.4
        ? '情况良好'
        : '十分优秀'
    },
    getWrongDifficulityRate: function (e) {
      var t = this.getdetailObject(e),
        n = Object.values(t)
          .filter(function (e) {
            return e.result.f.length
          })
          .reduce(function (e, t) {
            return e + t.result.f.length
          }, 0),
        r = Object.values(t)
          .filter(function (e) {
            return e.result.f.length
          })
          .reduce(function (n, e) {
            return (
              e.result.f.forEach(function (e) {
                var t = ''
                0.2 === e
                  ? (t = '简单题')
                  : 0.6 === e
                  ? (t = '中等难度题')
                  : 0.8 === e && (t = '难题'),
                  n[e] || (n[e] = { name: t, value: 0 }),
                  n[e].value++
              }),
              n
            )
          }, {})
      return Object.entries(r)
        .sort(function (e, t) {
          return Number(e[0]) - Number(t[0])
        })
        .map(function (e) {
          return [e[1].name, ((100 * e[1].value) / n).toFixed(0) + '%']
        })
    },
    getTips: function (e) {
      var t = this.getdetailObject(e),
        n = Object.values(t)
          .filter(function (e) {
            return e.result.f.length
          })
          .map(function (e) {
            return e.id
          })
      return Object.entries(this.description[e])
        .filter(function (e) {
          return n.includes(Number(e[0]))
        })
        .map(function (e) {
          return e[1]
        })
    },
    getWrongPointList: function (e) {
      var t = this.getdetailObject(e)
      return Object.values(t)
        .filter(function (e) {
          return e.result.f.length
        })
        .map(function (e) {
          return e.name
        })
    },
    getWrongPointRate: function (e) {
      var t = this.getdetailObject(e),
        n = Object.values(t)
          .filter(function (e) {
            return e.result.f.length
          })
          .reduce(function (e, t) {
            return e + t.result.f.length
          }, 0)
      return Object.values(t)
        .filter(function (e) {
          return e.result.f.length
        })
        .map(function (e) {
          return [e.name, ((100 * e.result.f.length) / n).toFixed(0) + '%']
        })
    },
    getdetailObject: function (e) {
      var r = this.description[e]
      return this.details[e].reduce(function (e, t) {
        var n = t[1]
        return (
          e[n] ||
            (e[n] = {
              id: Number(n),
              name: r[n][0],
              description: r[n][1],
              result: { t: [], f: [] }
            }),
          t[0] ? e[n].result.t.push(t[2]) : e[n].result.f.push(t[2]),
          e
        )
      }, {})
    },
    judegeDone: function (e) {
      return this.assresults[e] !== null
    },
    /**
     * @func 生成一个饼图
     * @param id 被挂载父元素ID
     * @param titletext 饼图的标题
     * @param titlesubtext 副标题
     * @param tabledata 数值
     */
    initPieGraph: function (id, titletext, tabledata) {
      var pieGraph = this.$echarts.init(document.getElementById(id))
      var option
      option = {
        title: {
          text: titletext,
          left: 'center',
          textStyle: {
            fontWeight: 'bold', //标题颜色
            color: '#000'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} <br/> {c} ({d}%)'
        },
        color: ['#f17c67', '#2ea9df', '#FFB11B', '#86C166', '#9B90C2'],
        series: [
          {
            type: 'pie',
            radius: '65%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            data: tabledata,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              normal: {
                show: true,
                formatter: '{b}: {c}({d}%)'
              }
            }
          }
        ]
      }
      option && pieGraph.setOption(option)
    },
    scrollToTop: function () {
      if (!document.body.scrollTop || !document.documentElement.scrollTop)
        if (document.documentElement.scrollTop)
          var l = setInterval(function () {
            ;(document.documentElement.scrollTop =
              0.6 * document.documentElement.scrollTop),
              document.documentElement.scrollTop < 1 &&
                (clearInterval(l), (document.documentElement.scrollTop = 0))
          }, 30)
        else if (document.body.scrollTop)
          var n = setInterval(function () {
            ;(document.body.scrollTop = 0.6 * document.body.scrollTop),
              document.body.scrollTop < 1 &&
                (clearInterval(n), (document.body.scrollTop = 0))
          }, 30)
    },
  },
  mounted: function () {
    let that = this
    querySubjectDone({}).then(res => {
      if (res.rescode == 200) {
        that.assresults = res.resultStatus
        querySubjectResult({}).then(res => {
          if (res.rescode == 200) {
            that.details = res.data
            this.subjectNames.forEach(element => {
              if (this.details[element[1]] !== undefined) {
                let basedata = this.getWrongPointRate(element[1])
                let data = []
                basedata.forEach(point => {
                  data.push({
                    value: Number(point[1].replace('%', '')),
                    name: point[0]
                  })
                })
                this.initPieGraph(
                  `container-wrong-point-${element[1]}`,
                  `${element[0]}错题类别分布`,
                  data
                )
                basedata = this.getWrongDifficulityRate(element[1])
                data = []
                basedata.forEach(point => {
                  data.push({
                    value: Number(point[1].replace('%', '')),
                    name: point[0]
                  })
                })
                this.initPieGraph(
                  `container-wrong-rate-${element[1]}`,
                  `${element[0]}错题难度分布`,
                  data
                )
              }
              this.scrollToTop()
            })
          }
        })
      }
    })
  }
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/test/result.min.css');
.main-title {
  padding: 0;
}
.not-done {
  font-size: 2rem;
  border-bottom: 1px solid #f17c67;
  margin-bottom: 1rem;
  p {
    color: #000;
  }
  font-weight: bold;
}
</style>
